import useDarkMode from 'use-dark-mode';
import {
  MuiThemeProvider,
  AppBar,
  Toolbar,
  Typography,
  IconButton,
  Tooltip,
  CssBaseline,
  Grid,
  Button,
  CircularProgress,
  Container,
} from '@material-ui/core';
import Brightness3Icon from '@material-ui/icons/Brightness3';
import WbSunnyIcon from '@material-ui/icons/WbSunny';
import { useEffect, useState } from 'react';
import { BigNumber, Contract, ethers } from 'ethers';
import detectEthereumProvider from '@metamask/detect-provider';
import darkTheme from './themes/dark';
import lightTheme from './themes/light';
import { getAccounts } from './helpers/getAccounts';
import abi from './abis/nft.json';
import NFTCard from './components/NFTCard';
import {
  CHAINID,
  CONTRACT_ADDRESS,
  FALLBACK_RPC_URL,
  OPENSEA_URL,
} from './constants';

const App = () => {
  const darkMode = useDarkMode();
  const theme = darkMode.value ? darkTheme : lightTheme;
  const [account, setAccount] = useState();
  const [totalSupply, setTotalSupply] = useState(0);
  const [allTokenIDs, setAllTokenIDs] = useState<number[]>([]);
  const [connecting, setConnecting] = useState(false);
  const [ownerOfMap, setOwnerOfMap] = useState<{ [key: number]: string }>({});
  const [ownedTokenIDs, setOwnedTokenIDs] = useState([]);
  const [tokenMetadata, setTokenMetadata]: [any, any] = useState({});
  const [chainId, setChainId] = useState<string>();
  const [provider, setProvider] = useState<any>();
  const [metaMaskProvider, setMetaMaskProvider] = useState<any>();
  const [contract, setContract] = useState<Contract>();

  useEffect(() => {
    detectEthereumProvider().then(setMetaMaskProvider);
  }, []);

  useEffect(() => {
    if (contract) {
      contract.totalSupply().then((t: BigNumber) => {
        setTotalSupply(t.toNumber());
      });
    }
  }, [contract]);

  useEffect(() => {
    if (metaMaskProvider) {
      const p = new ethers.providers.Web3Provider(metaMaskProvider);
      const c = new ethers.Contract(CONTRACT_ADDRESS, abi, p);
      c.deployed().then(() => {
        setContract(c);
        setProvider(p);
      });
    } else {
      const p = new ethers.providers.JsonRpcProvider(FALLBACK_RPC_URL);
      const c = new ethers.Contract(CONTRACT_ADDRESS, abi, p);
      c.deployed().then(() => {
        setContract(c);
        setProvider(p);
      });
    }
  }, [metaMaskProvider, chainId]);

  const fetchSkinForTokenID = async (tokenID: ethers.BigNumber) => {
    const results = await contract?.tokenURI(tokenID, {
      gasLimit: '3500000000000',
    });
    return results;
  };

  const onConnectClick = () => {
    setConnecting(true);
    getAccounts(true)
      .then(setAccount)
      .then(() => setConnecting(false))
      .catch(() => setConnecting(false));
  };

  useEffect(() => {
    if (contract && totalSupply) {
      contract.walletOfOwner(account).then((_ownedTokenIDs: any) => {
        setOwnedTokenIDs(_ownedTokenIDs);
      });
    }
  }, [contract, account, chainId, totalSupply]);

  useEffect(() => {
    if (totalSupply > 0) {
      for (let i = 0; i < totalSupply; i++) {
        setAllTokenIDs((_allTokenIDs: number[]) => [..._allTokenIDs, i]);
      }
    }
  }, [totalSupply]);

  useEffect(() => {
    if (metaMaskProvider?.request) {
      metaMaskProvider?.request({ method: 'eth_chainId' }).then(setChainId);
    } else if (provider) {
      provider.getNetwork().then((res: any) => {
        return setChainId(
          ethers.utils.hexStripZeros(BigNumber.from(res.chainId).toHexString()),
        );
      });
    }
  }, [metaMaskProvider, provider]);

  useEffect(() => {
    if (contract) {
      for (const tokenId of allTokenIDs) {
        contract.ownerOf(tokenId).then((owner: string) => {
          setOwnerOfMap((_oldMap) => {
            return { ..._oldMap, [tokenId]: owner };
          });
        });
      }
    }
  }, [contract, allTokenIDs]);

  useEffect(() => {
    allTokenIDs.forEach(async (tokenID: number) => {
      const rawString = (await fetchSkinForTokenID(
        BigNumber.from(tokenID),
      )) as string;
      if (!rawString) {
        return;
      }
      const svg = await contract?.tokenIdToSVG(tokenID, {
        gasLimit: '3500000000000',
      });

      const metadata = JSON.parse(
        atob(rawString.split('data:application/json;base64,')[1]),
      );
      setTokenMetadata((prevTokenMetadata: any) => {
        return {
          ...prevTokenMetadata,
          [tokenID.toString()]: { ...metadata, svg },
        };
      });
    });
  }, [contract, allTokenIDs]);

  useEffect(() => {
    ownedTokenIDs.forEach(async (tokenID: ethers.BigNumber) => {
      const rawString = (await fetchSkinForTokenID(tokenID)) as string;
      if (!rawString) {
        return;
      }

      const metadata = JSON.parse(
        atob(rawString.split('data:application/json;base64,')[1]),
      );
      setTokenMetadata((prevTokenMetadata: any) => {
        return {
          ...prevTokenMetadata,
          [tokenID.toString()]: metadata,
        };
      });
    });
  }, [ownedTokenIDs]);

  useEffect(() => {
    if (!metaMaskProvider) {
      return;
    }

    metaMaskProvider
      .request({
        method: 'wallet_getPermissions',
        params: [],
      })
      .then((accounts: any[]) => {
        if (accounts.length > 0) {
          setAccount(accounts[0].caveats[0].value[0]);
        }
      });
  }, [metaMaskProvider]);

  const switchChain = async () => {
    if (!metaMaskProvider) {
      return;
    }

    await metaMaskProvider.request({
      method: 'wallet_switchEthereumChain',
      params: [{ chainId: CHAINID }],
    });

    setTimeout(async () => {
      const chainIdResult = await metaMaskProvider.request({
        method: 'eth_chainId',
        params: [],
      });
      setChainId(chainIdResult);
    }, 1000);
  };

  return (
    <MuiThemeProvider theme={theme}>
      <AppBar position="sticky" color="default" elevation={0}>
        <Toolbar>
          <Grid
            container
            alignContent="center"
            alignItems="center"
            justifyContent="space-between"
            xs={12}
          >
            <Typography variant="h6">
              artifacts.gg
              <Typography variant="subtitle1" color="secondary">
                100% on-chain animated 1/1 NFT collection
              </Typography>
            </Typography>
            <Grid item>
              {chainId === CHAINID ? (
                <Button
                  startIcon={
                    connecting ? (
                      <CircularProgress variant="indeterminate" />
                    ) : undefined
                  }
                  style={{ marginRight: '45px' }}
                  onClick={onConnectClick}
                  variant="outlined"
                >
                  {account || <div>Connect</div>}
                </Button>
              ) : (
                <Button
                  variant="outlined"
                  onClick={switchChain}
                  style={{ marginRight: '45px' }}
                >
                  Switch Chain
                </Button>
              )}
              <Tooltip title={'Go To Twitter'}>
                <IconButton
                  href="https://twitter.com/shanejonas"
                  target="_blank"
                >
                  <img height="20" src={'images/twitter.svg'} />
                </IconButton>
              </Tooltip>
              <Tooltip title={'Go To Opensea'}>
                <IconButton href={OPENSEA_URL} target="_blank">
                  <img
                    height="24"
                    src={
                      'https://storage.googleapis.com/opensea-static/Logomark/Logomark-Blue.svg'
                    }
                  />
                </IconButton>
              </Tooltip>

              <Tooltip title={'Toggle Dark Mode'}>
                <IconButton onClick={darkMode.toggle}>
                  {darkMode.value ? <Brightness3Icon /> : <WbSunnyIcon />}
                </IconButton>
              </Tooltip>
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>
      <div>
        <CssBaseline />
        <Container maxWidth="xl">
          <Grid
            container
            alignContent="center"
            alignItems="center"
            justifyContent="center"
            direction="column"
          >
            <div style={{ paddingTop: '70px' }} />
            <Typography variant="h4">NFTs ({allTokenIDs.length})</Typography>
            <Grid
              container
              justifyContent="center"
              style={{ paddingBottom: '40px' }}
            >
              {allTokenIDs?.map((tokenId: any) => {
                const metadata = tokenMetadata[tokenId.toString()];
                return (
                  <Grid item spacing={4}>
                    <NFTCard
                      metadata={metadata}
                      tokenId={tokenId}
                      ownerOfMap={ownerOfMap}
                    />
                  </Grid>
                );
              })}
            </Grid>
          </Grid>
        </Container>
      </div>
    </MuiThemeProvider>
  );
};

export default App;
