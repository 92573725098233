import { createMuiTheme } from '@material-ui/core/styles';

const lightTheme = createMuiTheme({
  typography: {
    fontFamily: 'courier',
  },
  props: {
    MuiAppBar: {
      position: 'sticky',
    },
    MuiCard: {
      elevation: 0,
    },
  },
  overrides: {
    MuiButton: {
      root: {
        borderRadius: '0px',
      },
    },
    MuiCard: {
      root: {
        background: 'rgba(0, 0, 0, 0.8)',
      },
    },
    MuiAppBar: {
      root: {
        background: '#fff !important',
      },
    },
  },
  palette: {
    background: {
      default: '#fff',
    },
  },
});

export default lightTheme;
