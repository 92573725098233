import { Card, CardContent, Chip, Typography, Grid } from '@material-ui/core';
import { Tooltip, IconButton } from '@mui/material';
import { OPENSEA_ASSET_URL } from '../constants';

const SCALE = 11;

const NFTCard = ({ metadata, tokenId, ownerOfMap }: any) => {
  if (!metadata) {
    return null;
  }

  return (
    <Card
      key={tokenId}
      style={{
        textAlign: 'center',
        marginBottom: '30px',
        margin: '15px',
        padding: '10px',
        paddingTop: '10px',
        borderRadius: '0px',
        width: '400px',
        minHeight: '450px',
      }}
    >
      <Grid container justify="space-between">
        <Grid item>
          <Typography
            style={{
              color: 'white',
              fontFamily: 'courier',
              textAlign: 'left',
              marginLeft: '4px',
              marginTop: '8px',
            }}
          >
            {metadata.name}
          </Typography>
        </Grid>
        <Grid item>
          <Tooltip title={'OpenSea'}>
            <IconButton href={OPENSEA_ASSET_URL + tokenId} target="_blank">
              <img
                height="24"
                src={
                  'https://storage.googleapis.com/opensea-static/Logomark/Logomark-Blue.svg'
                }
              />
            </IconButton>
          </Tooltip>
        </Grid>
      </Grid>
      <CardContent style={{ paddingTop: '30px' }}>
        <img
          src={metadata.image}
          style={{
            imageRendering: 'pixelated',
            margin: '0 auto',
            height: `${26 * SCALE}px`,
            width: `${26 * SCALE}px`,
          }}
        />
      </CardContent>
      <CardContent>
        {metadata.attributes.map((attribute: any) => {
          if (attribute.value === 'None') {
            return null;
          }
          return (
            <Tooltip
              title={attribute.trait_type}
              key={attribute.trait_type + attribute.value}
            >
              <Chip label={attribute.value} style={{ margin: '2px' }} />
            </Tooltip>
          );
        })}
        <Typography>Owner:</Typography>
        <Chip label={ownerOfMap[tokenId]} style={{ margin: '2px' }} />
      </CardContent>
    </Card>
  );
};

export default NFTCard;
