import { createMuiTheme } from '@material-ui/core/styles';
import grey from '@material-ui/core/colors/grey';

const darkTheme = createMuiTheme({
  typography: {
    fontFamily: 'courier',
  },
  props: {
    MuiAppBar: {
      position: 'sticky',
    },
    MuiCard: {
      elevation: 0,
    },
  },
  palette: {
    type: 'dark',
    background: {
      default: grey[900],
      paper: grey[800],
    },
  },
  overrides: {
    MuiAppBar: {
      root: {},
    },
    MuiTable: {
      root: {},
    },
    MuiButton: {
      root: {
        borderRadius: '0px',
      },
    },
    MuiTypography: {
      root: {
        color: grey[400],
      },
    },
  },
});

export default darkTheme;
