export const getAccounts = async (force = false) => {
  if (!force) {
    const accounts = await (window as any).ethereum.request({
      method: 'wallet_getPermissions',
      params: [
        {
          eth_accounts: {},
        },
      ],
    });
    if (accounts.length > 0) {
      return accounts[0].caveats[0].value[0];
    }
  }
  const pAccounts = await (window as any).ethereum.request({
    method: 'eth_requestAccounts',
    params: [],
  });
  if (pAccounts.length > 0) {
    return pAccounts[0];
  }
  return undefined;
};
